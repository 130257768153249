// 把功能封装到这里
const dayjs = require('dayjs')

function ggg() {
    console.log('ggg')
}

function aaa() {
    console.log('aaa')
}

function ccc() {
    console.log(dayjs().format('YYYY-MM-DD'))
}
// 前面无论封装了什么功能,总之最后一定要导出你的功能
module.exports = { ggg, aaa, ccc }